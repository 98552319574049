'use strict';

const taiToUtc = -37; // Used if metadata_processing is missing
const taiToGPS = -19;
const gpsToTai = -taiToGPS;
const gpsEpochToUnixEpoch = -315964800; // unix = gps - gpsEpochToUnixEpoch
                                        // gps time at 1980 is 0
                                        // unix time at 1980 is 315,964,800 (because unix epoch is 1970), so
const unixEpochToGpsEpoch = -gpsEpochToUnixEpoch;
const nsToSeconds = 10 ** -9;
const attributeOffset = 10 ** 9;

export function convertTaiToGps(timestampTAI) {
    if (!window.VeritasInfo.utcOffset0 || !window.VeritasInfo.utcOffset1) { // if controlPoints provides TAI, but truth provides UTC
        return timestampTAI.toFloat64() * nsToSeconds + taiToUtc;
    }else{
        return timestampTAI.toFloat64() * nsToSeconds + taiToGPS + gpsEpochToUnixEpoch;
    }
}

export function convertGpsToUtc(timestamp) {
    if (!window.VeritasInfo.utcOffset0 || !window.VeritasInfo.utcOffset1) {
        return timestamp + unixEpochToGpsEpoch + gpsToTai + taiToUtc;
    }
    else if (window.VeritasInfo.utcOffsetTimestamp && timestamp > convertTaiToGps(window.VeritasInfo.utcOffsetTimestamp)) {
        return timestamp + unixEpochToGpsEpoch + gpsToTai - window.VeritasInfo.utcOffset1;
    }
    else {
        return timestamp + unixEpochToGpsEpoch + gpsToTai - window.VeritasInfo.utcOffset0;
    }
}

// 2.X datasets need a 1e9 offset applied to their timestamp attributes
export function applyAttributeTimestampOffset(timestamp) {
    return timestamp + attributeOffset;
}

export function isTaiExist(state) {
    const isTAI = state.timestampTAI &&
        (state.timestampTAI().low || state.timestampTAI().high) &&
        state.timestampTAI().toFloat64() > 1e-7 // need to make sure it's not 0

    if (!isTAI) {
        console.warn("[DEPRECATED] No TAI timestamps found, using Deprecated UTC Timestamps");
    }

    return isTAI;
}

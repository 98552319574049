import * as THREE from "../../libs/three.js/build/three.module.js";
import { PointCloudMaterial } from "../../src/materials/PointCloudMaterial.js";

export class VeritasMaterial extends PointCloudMaterial {
    constructor() {
        super();

        this.attributes = {
            ...this.attributes,
            dualDistance:       { type: 'f', value: [] },
            dualReflectivity:   { type: 'f', value: [] },
            confidence:         { type: 'f', value: [] },
            isRecommendedDrop:  { type: 'f', value: [] },
            insOrientation:     { type: 'v3', value: new THREE.Vector3(0, 0, 0) },
            insPosition:        { type: 'v3', value: new THREE.Vector3(0, 0, 0) }
        }

        this.uniforms = {
            ...this.uniforms,
			uExtrinsicsMode: 	    { type: "b", value: false },
            wDualDistance:          { type: "f", value: 0 },
            wDualReflectivity:      { type: "f", value: 0 },
            wConfidence:            { type: "f", value: 0 },
            uFilterInvalidPoints:   { type: 'b', value: true },
            uVisualizeTimeRange:    { type: "b", value: true },
            currentRtkPosition:     { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            currentRtkOrientation:  { type: "v3", value: new THREE.Vector3(0 ,0, 0) },
            rtk2VehicleXYZOld:      { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            rtk2VehicleRPYOld:      { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            velo2RtkXYZOld:         { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            velo2RtkRPYOld:         { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            rtk2VehicleXYZNew:      { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            rtk2VehicleRPYNew:      { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            velo2RtkXYZNew:         { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            velo2RtkRPYNew:         { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            uCalMatrix:             { type: "Matrix4fv", value: new THREE.Matrix4() },
            intensityRange:		    { type: "fv", value: [0, 255] },
			intensity_gbc: 		    { type: "fv", value: [1, 0, 0]},
			wRGB:				    { type: "f", value: 0 },
			wIntensity:			    { type: "f", value: 1 },
			wElevation:			    { type: "f", value: 1 },
            uFilterConfidenceValueRange:	{ type: "fv", value: [0, 7]}
        }
    }
}

import * as THREE from "../../libs/three.js/build/three.module.js";
import {BinaryLoader} from "../../src/loader/BinaryLoader.js";
import {unpackDual} from "../schemas/Lidar_functions.js";
import {unpackConfidence} from "../schemas/Lidar_functions.js";
import {PointAttribute, PointAttributeTypes} from "../../src/loader/PointAttributes.js";

// Define custom attributes
PointAttribute.RTK_POSE = new PointAttribute("RTK_POSE", PointAttributeTypes.DATA_TYPE_DOUBLE, 3);
PointAttribute.RTK_ORIENT = new PointAttribute("RTK_ORIENT", PointAttributeTypes.DATA_TYPE_DOUBLE, 3);
PointAttribute.DUAL_PLUS_CONFIDENCE = new PointAttribute("DUAL_PLUS_CONFIDENCE", PointAttributeTypes.DATA_TYPE_UINT16, 1);
PointAttribute.LATITUDE = new PointAttribute("LATITUDE", PointAttributeTypes.DATA_TYPE_DOUBLE, 1);
PointAttribute.LONGITUDE = new PointAttribute("LONGITUDE", PointAttributeTypes.DATA_TYPE_DOUBLE, 1);
PointAttribute.ALTITUDE = new PointAttribute("ALTITUDE", PointAttributeTypes.DATA_TYPE_DOUBLE, 1);

export class VeritasBinaryLoader extends BinaryLoader {
	constructor(version, boundingBox, scale){
		super(version, boundingBox, scale);
	}

    parse(node, buffer){
        let pointAttributes = node.pcoGeometry.pointAttributes;
        let numPoints = buffer.byteLength / node.pcoGeometry.pointAttributes.byteSize;

        if (this.version.upTo('1.5')) {
            node.numPoints = numPoints;
        }

        let workerPath = Potree.scriptPath + '/workers/VeritasBinaryDecoderWorker.js';
        let worker = Potree.workerPool.getWorker(workerPath);

        worker.onmessage = function (e) {

            let data = e.data;
            let buffers = data.attributeBuffers;
            let tightBoundingBox = new THREE.Box3(
                new THREE.Vector3().fromArray(data.tightBoundingBox.min),
                new THREE.Vector3().fromArray(data.tightBoundingBox.max)
            );

            Potree.workerPool.returnWorker(workerPath, worker);

            let geometry = new THREE.BufferGeometry();

            for(let property in buffers){
                let buffer = buffers[property].buffer;
                let batchAttribute = buffers[property].attribute;

                if (property === "POSITION_CARTESIAN") {
                    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(buffer), 3));
                } else if (property === "rgba") {
                    geometry.setAttribute("rgba", new THREE.BufferAttribute(new Uint8Array(buffer), 4, true));
                } else if (property === "NORMAL_SPHEREMAPPED") {
                    geometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(buffer), 3));
                } else if (property === "NORMAL_OCT16") {
                    geometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(buffer), 3));
                } else if (property === "NORMAL") {
                    geometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(buffer), 3));
                } else if (property === "INDICES") {
                    let bufferAttribute = new THREE.BufferAttribute(new Uint8Array(buffer), 4);
                    bufferAttribute.normalized = true;
                    geometry.setAttribute('indices', bufferAttribute);
                } else if (property === "SPACING") {
                    let bufferAttribute = new THREE.BufferAttribute(new Float32Array(buffer), 1);
                    geometry.setAttribute('spacing', bufferAttribute);
				} else if (property === "RTK_POSE") {
						geometry.setAttribute('insPosition', new THREE.BufferAttribute(new Float32Array(buffer), 3, false));
				} else if (property === "RTK_ORIENT") {
						geometry.setAttribute('insOrientation', new THREE.BufferAttribute(new Float32Array(buffer), 3, false));
				} else if (property === "DUAL_PLUS_CONFIDENCE") {
						let DPCs = new Uint16Array(buffer);
						let dualDistance = new Uint8Array(DPCs.map(function(DPC) { return unpackDual(DPC).distFlag }));
						let dualReflectivity = new Uint8Array(DPCs.map(function(DPC) { return unpackDual(DPC).intenFlag }));
						let confidence = new Uint8Array(DPCs.map(function(DPC) { return unpackConfidence(DPC).confidence }));
						let isRecommendedDrop = new Uint8Array(DPCs.map(function(DPC) { return unpackConfidence(DPC).isRecommendedDrop }));
						geometry.setAttribute('dualDistance', new THREE.BufferAttribute(dualDistance, 1));
						geometry.setAttribute('dualReflectivity', new THREE.BufferAttribute(dualReflectivity, 1));
						geometry.setAttribute('confidence', new THREE.BufferAttribute(confidence, 1));
						geometry.setAttribute('isRecommendedDrop', new THREE.BufferAttribute(isRecommendedDrop, 1));
				} else if (property === "LATITUDE") {
						let bufferAttribute = new THREE.BufferAttribute(new Float32Array(buffer), 1, false);
						geometry.setAttribute('latitude', bufferAttribute);
				} else if (property === "LONGITUDE") {
						let bufferAttribute = new THREE.BufferAttribute(new Float32Array(buffer), 1, false);
						geometry.setAttribute('longitude', bufferAttribute);
				} else if (property === "ALTITUDE") {
					geometry.setAttribute('altitude', new THREE.BufferAttribute(new Float32Array(buffer), 1));
                } else {
                    const bufferAttribute = new THREE.BufferAttribute(new Float32Array(buffer), 1);

                    bufferAttribute.potree = {
                        offset: buffers[property].offset,
                        scale: buffers[property].scale,
                        preciseBuffer: buffers[property].preciseBuffer,
                        range: batchAttribute.range,
                    };

                    if (property === 'gps-time') {
                        //NOTE: the properties currently being read in as "gps-time" are actually in utc on 1.X datasets.
                        //  This also means that our calculated "utc-time" is incorrect.
                        //  This does not seem to cause issues with the functionality of potree, so a conversion is done before
                        //  timestamps are visible to users, but this is something to be aware of when working in 1.X
                        const gpsTimeValues = new Float32Array(buffer);

                        const timeFromStart = new THREE.BufferAttribute(gpsTimeValues.map(v => (v / buffers[property].scale + buffers[property].offset) - window.animationEngine.tstart), 1)
                        geometry.setAttribute('time from start', timeFromStart);

                        const utcTimeValues = new Float64Array(gpsTimeValues.length);
                        gpsTimeValues.forEach((v, i) => {
                            utcTimeValues[i] = v / buffers[property].scale + buffers[property].offset;
                        });
                        const utcTime = new THREE.BufferAttribute(utcTimeValues, 1);
                        geometry.setAttribute('utc-time', utcTime);
                    }

                    geometry.setAttribute(property, bufferAttribute);

                    const attribute = pointAttributes.attributes.find(a => a.name === batchAttribute.name);
                    attribute.range[0] = Math.min(attribute.range[0], batchAttribute.range[0]);
                    attribute.range[1] = Math.max(attribute.range[1], batchAttribute.range[1]);

                    if(node.getLevel() === 0){
                        attribute.initialRange = batchAttribute.range;
                    }

                }
            }

            tightBoundingBox.max.sub(tightBoundingBox.min);
            tightBoundingBox.min.set(0, 0, 0);

            let numPoints = e.data.buffer.byteLength / pointAttributes.byteSize;

            node.numPoints = numPoints;
            node.geometry = geometry;
            node.mean = new THREE.Vector3(...data.mean);
            node.tightBoundingBox = tightBoundingBox;
            node.loaded = true;
            node.loading = false;
            node.estimatedSpacing = data.estimatedSpacing;
            Potree.numNodesLoading--;
        };

        let message = {
            buffer: buffer,
            pointAttributes: pointAttributes,
            version: this.version.version,
            min: [ node.boundingBox.min.x, node.boundingBox.min.y, node.boundingBox.min.z ],
            offset: [node.pcoGeometry.offset.x, node.pcoGeometry.offset.y, node.pcoGeometry.offset.z],
            scale: this.scale,
            spacing: node.spacing,
            hasChildren: node.hasChildren,
            name: node.name
        };
        worker.postMessage(message, [message.buffer]);
    };

}

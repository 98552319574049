/**
 * Code adapted to javascript from https://github.com/JuliaGeo/Geodesy.jl transverse_mercator functions
 */

//Constants representing a WGS84 globe
const tm = {
    a: 6378137,
    e2: 0.00669437999,
    e2m: 0.99330562001,
    c: Math.sqrt(0.99330562001) * Math.exp(eatanhe(1.0, 0.00669437999)),
    f: .003352810665,
    alp: [.0008377318206244655, 7.608527773572229e-7,
        1.1976455033294337e-9, 2.4291706072013074e-12, 5.711757677865652e-15,
        1.491117731258342e-17],
    b1: 0.9983242984312529,
    bet: [0.0008377321640579443, 5.905870152220141e-8,
        1.67348266528397e-10, 2.1647980400626602e-13,
        3.787978046168505e-16, 7.248748890693924e-19]
}

export function VTMToLatLon(x, y, lat0, lon0, sf) {
    let coords0 = transverseMercatorForward(lon0, lat0, lon0, 1.0)
    return transverseMercatorReverse(lon0, x / sf, y / sf + coords0.y, 1.0)
}


function transverseMercatorForward(lon0, lat, lon, k0) {
    let maxPow = 6 //default Julia val

    lat = latFix(lat)
    lon = lon0 - lon
    if (Math.abs(lon) > 50) {
        throw console.error("Relative longitude is too large for transverse-Mercator expansion, and must be less than 50°. Possibly used wrong UTM zone.");
    }
    let latsign = lat < 0 ? -1 : 1
    let lonsign = lon < 0 ? -1 : 1
    lon *= lonsign
    lat *= latsign
    let backside = lon > 90
    if (backside) {
        if (lat == 0) {
            latsign = -1
        }
        lon = 180 - lon
    }

    let sphi = Math.sin(toRadians(lat))
    let cphi = Math.cos(toRadians(lat))
    let slam = Math.sin(toRadians(lon))
    let clam = Math.cos(toRadians(lon))

    let tau
    let taup
    let xip
    let etap
    let gamma
    let k
    if (lat != 90) {
        tau = sphi / cphi
        taup = taupf(tau, tm.e2)
        xip = Math.atan(taup, clam)
        etap = Math.asinh(slam / Math.hypot(taup, clam))
        gamma = atan2d(slam * taup, clam * Math.hypot(1.0, taup))
        k = Math.sqrt(tm.e2m + tm.e2 * cphi*cphi) * Math.hypot(1.0, tau) / Math.hypot(taup, clam)
    }
    else {
        xip = Math.PI/2
        etap = 0.0
        gamma = lon
        k = tm.c
    }

    let c0 = Math.cos(2 * xip)
    let ch0 = Math.cosh(2 * etap)
    let s0 = Math.sin(2 * xip)
    let sh0 = Math.sinh(2 * etap)
    let ar = 2 * c0 * ch0
    let ai = -2 * s0 * sh0
    let n = maxPow - 1

    let xi0 = 0.0 //note, utilizes maxpow
    let eta0 = 0.0
    let xi1 = 0.0
    let eta1 = 0.0
    /*
    if isodd(n)
        yr0 = 2 * MaxPow * tm.alp[n]
        n = n - 1

    else */
    let yr0 = 0.0
    let yi0 = 0.0
    let yr1 = 0.0
    let yi1 = 0.0
    while (n >= 0) {
        xi1  = ar * xi0 - ai * eta0 - xi1 + tm.alp[n]
        eta1 = ai * xi0 + ar * eta0 - eta1
        yr1 = ar * yr0 - ai * yi0 - yr1 + 2 * n * tm.alp[n]
        yi1 = ai * yr0 + ar * yi0 - yi1

        n = n - 1

        xi0  = ar * xi1 - ai * eta1 - xi0 + tm.alp[n]
        eta0 = ai * xi1 + ar * eta1 - eta0
        yr0 = ar * yr1 - ai * yi1 - yr0 + 2 * n * tm.alp[n]
        yi0 = ai * yr1 + ar * yi1 - yi0

        n = n - 1
    }

    ar *= 0.5
    ai *= 0.5
    yr1 = 1 - yr1 + ar * yr0 - ai * yi0
    yi1 =   - yi1 + ai * yr0 + ar * yi0
    ar = s0 * ch0
    ai = c0 * sh0

    let xi  = xip  + ar * xi0 - ai * eta0
    let eta = etap + ai * xi0 + ar * eta0

    gamma -= atan2d(yi1, yr1)
    k *= tm.b1 * Math.hypot(yr1, yi1)
    let y = tm.a * tm.b1 * k0 * (backside ? Math.PI - xi : xi) * latsign
    let x = tm.a * tm.b1 * k0 * eta * lonsign

    if (backside) {
        gamma = 180 - gamma
    }
    k *= k0
    return {
        x: x,
        y: y,
        sf: k
    }
}

function transverseMercatorReverse(lon0, x, y, k0) {
    let xi = y / (tm.b1 * tm.a * k0)
    let eta = x / (tm.b1 * tm.a * k0)
    let xisign = xi < 0 ? -1 : 1
    let etasign = eta < 0 ? -1 : 1
    xi *= xisign
    eta *= etasign
    let backside = xi > Math.PI / 2
    if (backside) {
        xi = Math.PI / 2
    }

    let c0 = Math.cos(2 * xi)
    let ch0 = Math.cosh(2 * eta)
    let s0 = Math.sin(2 * xi)
    let sh0 = Math.sinh(2 * eta)

    let ar = 2 * c0 * ch0
    let ai = -2 * s0 * sh0 // 2 * cos(2*zeta)
    let n = 6

    let xip0 = 0.0
    let etap0 = 0.0
    let xip1 = 0.0
    let etap1 = 0.0

    let yr0 = 0.0

    let yi0 = 0.0
    let yr1 = 0.0
    let yi1 = 0.0

    while (n > 0) {
        xip1 = ar * xip0 - ai * etap0 - xip1 - tm.bet[n-1]
        etap1 = ai * xip0 + ar * etap0 - etap1
        yr1 = ar * yr0 - ai * yi0 - yr1 - 2 * n * tm.bet[n-1]
        yi1 = ai * yr0 + ar * yi0 - yi1

        n = n - 1

        xip0 = ar * xip1 - ai * etap1 - xip0 - tm.bet[n-1]
        etap0 = ai * xip1 + ar * etap1 - etap0
        yr0 = ar * yr1 - ai * yi1 - yr0 - 2 * n * tm.bet[n-1]
        yi0 = ai * yr1 + ar * yi1 - yi0

        n = n - 1
    }

    ar *= 0.5
    ai *= 0.5
    yr1 = 1 - yr1 + ar * yr0 - ai * yi0
    yi1 = -yi1 + ai * yr0 + ar * yi0
    ar = s0 * ch0
    ai = c0 * sh0

    let xip = xi + ar * xip0 - ai * etap0
    let etap = eta + ai * xip0 + ar * etap0


    let gamma = Math.atan(yi1, yr1) * 180 / Math.PI
    let k = tm.b1 / Math.hypot(yr1, yi1)

    let s = Math.sinh(etap)
    let c = Math.max(0.0, Math.cos(xip))
    let r = Math.hypot(s, c)

    let lon
    let lat

    if (r != 0) {
        lon = Math.atan2(s, c) * 180 / Math.PI

        let sxip = Math.sin(xip)
        let tau = tauf(sxip / r, tm.e2)
        gamma += Math.atan(sxip * Math.tanh(etap), c) * 180 / Math.PI
        lat = toDegrees(Math.atan(tau))

        k *= Math.sqrt(tm.e2m + tm.e2 / (1 + tau * tau)) * Math.hypot(1.0, tau) * r
    } else {
        lat = 90.0
        lon = 0.0
        k *= tm.c
    }
    lat *= xisign
    if (backside) {
        lon = 180 - lon
    }
    lon *= etasign

    lon = lon + lon0

    k *= k0

    return {
        latitude: lat,
        longitude: lon,
        sf: k
    }
}


function tauf(taup, e2) {
    let numit = 5
    let e2m = 1 - e2
    let tau = taup / e2m
    //let stol = 0.1 * Math.sqrt(eps(Float64)) * Math.max(1.0, Math.abs(taup))
    for (let i = 1; i <= numit; i++) {
        let tau1 = Math.hypot(1, tau)
        let sig = Math.sinh(eatanhe(tau / tau1, e2))
        let taupa = Math.hypot(1, sig) * tau - sig * tau1
        let dtau = (taup - taupa) * (1 + e2m * tau * tau) / (e2m * tau1 * Math.hypot(1, taupa))
        tau = tau + dtau
        //g = g & (Math.abs(dtau) >= stol)
    }
    return tau
}



function latFix(x) {
    let y = x
    if (Math.abs(x) > 90) {
        y = NaN
    }
    return y
}

function atan2d(y, x) {
    let q = 0
    if (Math.abs(y) > Math.abs(x)) {
        let tmp = x
        x = y
        y = tmp
        q = 2
    }
    if (x < 0) {
        x = -x
        q = q + 1
    }
    let ang = Math.atan(y, x) * 180 / Math.PI
    if (q == 1) {
        ang = (y > 0 ? 180 : -180) - ang
    } else if (q == 2) {
        ang =  90 - ang
    } else if (q == 3) {
        ang = -90 + ang
    }
    return ang
}

function eatanhe(x, e2) {
    let e = Math.sqrt(Math.abs(e2))
    let y
    if (e2 >= 0) {
        y = e * Math.atanh(e * x)
    } else {
        y = -1 * e * Math.atan(e * x)
    }
    return y
}

function toDegrees (angle) {
    return angle * (180 / Math.PI);
}

function toRadians (angle) {
    return angle * (Math.PI / 180);
  }

function taupf(tau, e2) {
  let tau1 = Math.hypot(1, tau)
  let sig = Math.sinh(eatanhe( tau / tau1, e2 ) )
  return Math.hypot(1, sig) * tau - sig * tau1
}

/*
function setAlp(maxPow) {
let alpcoeff = [// alp[1]/n^1, polynomial in n of order 7
    -75900428, 37884525, 42422016, -89611200, 46287360, 63504000, -135475200,
    101606400, 203212800,
    // alp[2]/n^2, polynomial in n of order 6
    148003883, 83274912, -178508970, 77690880, 67374720, -104509440,
    47174400, 174182400,
    // alp[3]/n^3, polynomial in n of order 5
    318729724, -738126169, 294981280, 178924680, -234938880, 81164160,
    319334400,
    // alp[4]/n^4, polynomial in n of order 4
    -40176129013, 14967552000, 6971354016, -8165836800, 2355138720,
    7664025600,
    // alp[5]/n^5, polynomial in n of order 3
    10421654396, 3997835751, -4266773472, 1072709352, 2490808320,
    // alp[6]/n^6, polynomial in n of order 2
    175214326799, -171950693600, 38652967262, 58118860800,
    // alp[7]/n^7, polynomial in n of order 1
    -67039739596, 13700311101, 12454041600,
    // alp[8]/n^8, polynomial in n of order 0
    1424729850961, 743921418240]
    let o = 0;
    let n = .003352810665 / (2 - .003352810665)
    let d = n;
    let alp = [maxPow]
    for (let l = 0; l < maxPow; l++) { //TODO: unsure if < or <=
        let m = maxPow - l
        alp[l] = d * polyval(alpcoeff.slice(o,o+m), n) / alpcoeff[o + m + 1] //TODO: weird array stuff...
        //_bet[l] = d * polyval(betcoeff[o:o+m], n) / betcoeff[o + m + 1]
        o += m + 2
        d *= n
    }
    return alp
}



function polyval(p, x) {
    let y = 1
    let out = p[p.length - 1] //TODO: not sure about this one
    for (let i = p.length-1; i >= 0; i--) {
        y *= x
        out += p[i] * y
    }
    return out
}
*/

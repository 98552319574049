

import {Utils} from "../../utils.js";
import {addPointAttributes} from "../../../veritas/ui/custom-sidebar.js"
//import { updatePointPanel } from "../../../veritas/ui/custom-sidebar.js";
import { tooltipPrinter } from "../../../veritas/ui/printingUtilities.js";

// This file gets loaded twice. Make sure there is only one
// MeasurePanelPrinters object.
export const MeasurePanelPrinters = window.MeasurePanelPrinters ||
      {
        "position": null,
        "rgba": {prettyName: "rgb"}
      }
window.MeasurePanelPrinters = MeasurePanelPrinters;

export class MeasurePanel{

	constructor(viewer, measurement, propertiesPanel){
		this.viewer = viewer;
		this.measurement = measurement;
		this.propertiesPanel = propertiesPanel;

		this._update = () => { this.update(); };
	}

	createCoordinatesTable(points){
		let table = $(`
			<table class="measurement_value_table">
				<tr>
					<th>x</th>
					<th>y</th>
					<th>z</th>
					<th></th>
				</tr>
			</table>
		`);

		let copyIconPath = Potree.resourcePath + '/icons/copy.svg';

		for (let point of points) {
			let x = Utils.addCommas(point.x.toFixed(3));
			let y = Utils.addCommas(point.y.toFixed(3));
			let z = Utils.addCommas(point.z.toFixed(3));

			let row = $(`
				<tr>
					<td><span>${x}</span></td>
					<td><span>${y}</span></td>
					<td><span>${z}</span></td>
					<td align="right" style="width: 25%">
						<img name="copy" title="copy" class="button-icon" src="${copyIconPath}" style="width: 16px; height: 16px"/>
					</td>
				</tr>
			`);

			this.elCopy = row.find("img[name=copy]");
			this.elCopy.click( () => {
				let msg = point.toArray().map(c => c.toFixed(3)).join(", ");
				Utils.clipboardCopy(msg);

				this.viewer.postMessage(
					`Copied value to clipboard: <br>'${msg}'`,
					{duration: 3000});
			});

			table.append(row);
		}

		return table;
	};

	createAttributesTable(){
		let elTable = $('<table class="measurement_value_table"></table>');

		let point = this.measurement.points[0];

		point = addPointAttributes(point);
		for(let attributeName of Object.keys(point)){
                        const printer = MeasurePanelPrinters[attributeName];
			if(printer === null){

			}else{
				let value = point[attributeName];
				if (!(printer === undefined)) {
					value = printer.function(value)
				}
				if (tooltipPrinter(elTable, attributeName, value)) {
					continue
				}
				elTable.append($(`
					<tr>
						<td>${attributeName}</td>
						<td>${value}</td>
					</tr>
				`));

			}
		}
		return elTable;
	}

	update(){

	}
};

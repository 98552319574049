import { MeasurePanelPrinters } from "../../src/viewer/PropertyPanels/MeasurePanel.js";

//Conversion to have timestamps display correctly. See note in VeritasBinaryLoader.js
/*
In 1.X datasets, the data we see stored within potree as "gps-time" are actually utc timestamps.
Potree functions correctly internally with these timestamps, but the data users see would be incorrect if we gave
gps-time values directly. The hack below checks whether we're in a 1.X dataset by checking for the existence of the
"utcOffset" global variables that exist only within 2.X datasets. If it is found that we are in a 1.X dataset, gps-time
values are corrected to be actually in gps time through the offset below.
Note that this offset is only accurate to datasets from 2017 and later.
Also note that 2.X datasets correctly store gps-time values, and no offset is required.
*/
const utcToGps = 18

export function addPointMeasureFormatters() {
	MeasurePanelPrinters.position = null;
	MeasurePanelPrinters.latitude = {function: (value) => {return Number.parseFloat(value).toFixed(7);}};
	MeasurePanelPrinters.longitude = {function: (value) => {return Number.parseFloat(value).toFixed(7);}};
	MeasurePanelPrinters.altitude = {function: (value) => {return Number.parseFloat(value).toFixed(2);}};
	MeasurePanelPrinters['utc-time'] = {function: (value) => {
	  const t = new Date(1970, 0, 1) //UTC epoch
	  t.setSeconds(value[0])
	  //let isoDate = t.toISOString() <- would be helpful but doesnt work :(
	  const year = t.getFullYear()
	  const month = (Number.parseInt(t.getMonth()) + 1).toString().padStart(2, '0')
	  const day = t.getDate().toString().padStart(2, '0')
	  const hours = t.getHours().toString().padStart(2, '0')
	  const minutes = t.getMinutes().toString().padStart(2, '0')
	  const seconds = t.getSeconds().toString().padStart(2, '0')
	  const decimals = value[0].toString().split(".")[1] || 0
	  return `${year}-${month}-${day}\nT${hours}:${minutes}:${seconds}${decimals === 0 ? 'Z' : '.'+decimals+'Z'}`
	  //return isoDate.replace(".000", "").replace("T00", 'T' + ('0' + t.getHours()).slice(-2))
	}}
	MeasurePanelPrinters["gps-time"] = {function: (value) => {
		let time = value[0]
		if (!window.VeritasInfo.utcOffset0 && !window.VeritasInfo.utcOffset1) {
			time += utcToGps
		}
		//some weird rounding values are given when rounding numbers w/ less than 9 decimal places (which is all the time...)
		if (Number.parseFloat(time).toString().split(".")[1].length > 9) {
			return Number.parseFloat(time).toFixed(9);
		}
		return time
	}}
	MeasurePanelPrinters["time from start"] = {function: (value) => {return Number.parseFloat(value).toFixed(9);}};
	MeasurePanelPrinters.insPosition = {function: (value) => {
		return `${value[0].toFixed(3)},<br/>${value[1].toFixed(3)},<br/>${value[2].toFixed(3)}`
	}}
	MeasurePanelPrinters.insOrientation = {function: (value) => {
		return `${value[0].toFixed(4)},<br/>${value[1].toFixed(4)},<br/>${value[2].toFixed(4)}`
	}}
}

export function tooltipPrinter(elTable, attributeName, value) {
	if (value.toString().length > 20 && !value.toString().includes("<br/>")) {
	const styles = `
    	.attribute_value {
			position: relative;
			white-space: nowrap;
	  	}

	  	.attribute_value .attribute_tooltip {
			visibility: hidden;
			right: 32%;
			text-align: center;
			border: solid;
			border-radius: 3px;
			padding: 5px 0;
			background-color: #19282b;
			border-color: black;
			color: #fff;
			white-space: normal;
			position: absolute;
			z-index: 1;
		  }

		  .attribute_value:hover .attribute_tooltip {
			visibility: visible;
		  }
		`
	const styleSheet = document.createElement("style")
	styleSheet.innerText = styles
	document.head.appendChild(styleSheet)

	elTable.append($(`
		<tr>
			<td>${attributeName}</td>
			<td>
				<div class="attribute_value">${value}
					<span class="attribute_tooltip">${value}</span>
				</div>
			</td>
		</tr>
	`));
	return true
	}
	return false

}

import { getVolData, setSelectBoxByValue, updateMetadataText } from "../data-labeling/dropdown.js";
import { addVolLabelListeners } from "../data-labeling/dropdown.js";
import { addPointMeasureFormatters } from "./printingUtilities.js";
import {VTMToLatLon} from "../utilities/VTMConversions.js"

export function updateSidebar(vizConfiguration) {
  // Measure Panel Formatting
  addPointMeasureFormatters();

  // NOTE Call this function only after the sidebar has been initialized

  const tree = $("#jstree_scene");

  // Add track annotations menu
  const toolsMenu = $("#menu_tools")
  const trackDivider = $('<div id="track_annotation_tools_divider" class="divider" style="padding: 10px 0px 15px 0px"><span>Track Annotation Tools</span></div>')
  const trackMenu = $('<div id="track_annotation_tools"></div>');
  trackMenu.insertAfter(toolsMenu)
  trackDivider.insertAfter(toolsMenu)

  trackDivider.hide();
  trackMenu.hide();

  // Add child check/uncheck logic
  tree.on("uncheck_node.jstree", (e, data) => {
    const uncheckChildren = (node) => {
      if (node && node.children) {
        node.children.forEach(child => {
          tree.jstree("uncheck_node", child);
          uncheckChildren(child.children);
        });
      }
    }

    // Exclude annotations from this, checking/unchecking all of the annotations may cause dataset to explode
    if (!data.node.parents.includes("annotations")) {
      uncheckChildren(data.node);
    }
  });

  tree.on("check_node.jstree", (e, data) => {
    const checkChildren = (node) => {
      if (node && node.children) {
        node.children.forEach(child => {
          tree.jstree("check_node", child);
          checkChildren(child.children);
        });
      }
    }

    // Exclude annotations from this, checking/unchecking all of the annotations may cause dataset to explode
    if (!data.node.parents.includes("annotations")) {
      checkChildren(data.node);
    }
  });

  tree.on("select_node.jstree", (e, data) => {
    let object = data.node.data;
    if (object && object.name === "Vehicle Mesh") {
      const box = new THREE.Box3().setFromObject(object);
      const node = new THREE.Object3D();
      node.boundingBox = box;
      viewer.zoomTo(node, 5, 500);
    }
  });

  /*
  //TODO: create functionality for tooltips

  tree.on("hover_node.jstree", function (e, data) {
    let object = data.node
    if (object.text.length > 27) {
      let element = document.getElementById(object.id)
      element.attr("data-i18n", `[title]${object.text}`)
      element.i18n()
      console.log(element)


      //let rect = element.getBoundingClientRect()
      //let tooltip = $(`<span id="sidebar_tooltip" style="left:${rect.left};top:${rect.top};z-index:9999;">"${object.text}"</span>`)
      //viewer.scene.add(tooltip)
      //parentNode.after(tooltip[0])
      //element.after(tooltip[0])
    }
  });

  tree.on("dehover_node.jstree", function (e, data) {
    let object = data.node
    if (object.text.length > 27) {
      let tooltip = document.getElementById("sidebar_tooltip")
      while(tooltip) {
        tooltip.remove()
        tooltip = document.getElementById("sidebar_tooltip")
      }
    }
  });
  */

  // Initial cleanup
  tree.jstree("delete_node", $("#vectors"));
  tree.jstree("delete_node", $("#images"));

  // Fixing Return Number Filters
	let elReturnFilterPanel = $('#return_filter_panel');
  let sldReturnNumber = elReturnFilterPanel.find('#sldReturnNumber');
  let lblReturnNumber = elReturnFilterPanel.find('#lblReturnNumber');
  let sldNumberOfReturns = elReturnFilterPanel.find('#sldNumberOfReturns');
  let lblNumberOfReturns = elReturnFilterPanel.find('#lblNumberOfReturns');

  // FIX RETURN NUMBER FILTER
  window.viewer.filterReturnNumberRange = [0, 2];
  sldReturnNumber.slider({min: 0, max: 2});
  lblReturnNumber[0].innerHTML = `${0} to ${2}`;

  // FIX NUMBER OF RETURNS FILTER
  window.viewer.filterNumberOfReturnsRange = [1, 2];
  sldNumberOfReturns.slider({min: 1, max: 2});
  lblNumberOfReturns[0].innerHTML = `${1} to ${2}`;

  // Custom classifications
  const elClassificationList = $("#classificationList");
  let addClassificationItem = (code, name) => {
    let inputID = 'chkClassification_' + code;

    let element = $(`
      <li>
        <label style="whitespace: nowrap">
          <input id="${inputID}" type="checkbox" checked/>
          <span>${name}</span>
        </label>
      </li>
    `);

    let elInput = element.find('input');

    elInput.click(event => {
      window.viewer.setClassificationVisibility(code, event.target.checked);
    });

    elClassificationList.append(element);
  };

  elClassificationList.empty();

  addClassificationItem(0, 'Unknown');
  addClassificationItem(1, 'Road');
  addClassificationItem(2, 'Lane Markings');
  addClassificationItem(3, 'NonRoad');


  const confidenceListDivider = $('<div class="divider" style="padding: 10px 0px 15px 0px"><span>Confidence</span></div>');
  const confidenceList = $('<ul id="confidenceList" class="pv-menu-list"></ul>');

  confidenceList.insertAfter(elClassificationList);
  confidenceListDivider.insertAfter(elClassificationList);

  const addConfidenceItem = (name) => {
    const inputID = 'chkConfidence_' + name;

    const element = $(`
      <li>
        <label style="whitespace: nowrap">
          <input id="${inputID}" type="checkbox"/>
          <span>${name}</span>
        </label>
      </li>
    `);

    const elInput = element.find('input');

    const setInvalidPointVisibility = (value) => {
      for (let pc of window.viewer.scene.pointclouds) {
        pc.material.uniforms.uFilterInvalidPoints.value = !value;
      }
    }

    elInput.click(event => {
      setInvalidPointVisibility(event.target.checked);
    });

    confidenceList.append(element);
  };

  addConfidenceItem("Invalid Returns");

  //CONFIDENCE VALUE FILTER
  let elConfidenceSlider = $(`<ul class="pv-menu-list">
      <li><span>Confidence</span>: <span id="lblConfidenceValue"></span> <div id="sldConfidenceValue"></div></li>
    </ul>`)
  let sldConfidenceValue = elConfidenceSlider.find('#sldConfidenceValue');
  let lblConfidenceValue = elConfidenceSlider.find('#lblConfidenceValue');

  window.viewer.filterConfidenceValueRange = [0, 7];

  window.viewer.setFilterConfidenceValueRange = (from, to) => {
		window.viewer.filterConfidenceValueRange = [from, to];
		window.viewer.dispatchEvent({'type': 'filter_confidence_value_range_changed', 'viewer': window.viewer});
	}

  sldConfidenceValue.slider({
    range: true,
    min: 0, max: 7, step: 1,
    values: [0, 7],
    slide: (event, ui) => {
      window.viewer.setFilterConfidenceValueRange(ui.values[0], ui.values[1])
    }
  });

  let onConfidenceValueChanged = (event) => {
    let [from, to] = window.viewer.filterConfidenceValueRange;

    lblConfidenceValue[0].innerHTML = `${from} to ${to}`;
    sldConfidenceValue.slider({values: [from, to]});
  };

  window.viewer.addEventListener('filter_confidence_value_range_changed', onConfidenceValueChanged);
  onConfidenceValueChanged();

  confidenceList.append(elConfidenceSlider);

  let createNode = (parent, text, icon, object, sort=false) => {
    let nodeID = tree.jstree('create_node',
        parent,
        {
          "text": text,
          "icon": icon,
          "data": object
        },
        sort ? getSortedIndex(tree.jstree("get_children_dom", parent), text) : "last",
        false,
        false);

    if(object.visible){
      tree.jstree('check_node', nodeID);
    }else{
      tree.jstree('uncheck_node', nodeID);
    }

    return nodeID;
  }

  const getSortedIndex = (nodes, text) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].textContent.localeCompare(text) > 0) {
        return i;
      }
    }
    return "last";
  }

  const createNodeFolderSorted =
    (parent, text, id) =>
      tree.jstree('create_node',
                  parent,
                  {
                    "text": text,
                    "id": id
                  },
                  getSortedIndex(tree.jstree("get_children_dom", parent), text),
                  false,
                  false);

  const vehicleTree = tree.jstree('create_node', "#", { "text": "<b>Vehicle</b>", "id": "vehicleViz"}, "first", false, false);
  tree.jstree("check_node", vehicleTree);
  let onVehicleLayerAdded = (e) => {
    const vehicleLayer = e.vehicleLayer;
    const iconName = {
      "RTK Trajectory": "trajectory",
      "Cartesian Grid": "cartesian_grid",
      "Polar Grid": "polar_grid",
      "3D Axes": "axes",
      "Vehicle Mesh": "vehicle"
    }[vehicleLayer.name]
    let vehicleIcon = `${Potree.resourcePath}/veritasIcons/${iconName}.svg`
    if (!iconName) {
      vehicleIcon = `${Potree.resourcePath}/icons/cloud.svg`
    }
    let node = createNode(vehicleTree, vehicleLayer.name, vehicleIcon, vehicleLayer);

    addCheckListeners(vehicleLayer, tree, node)
  };
  window.viewer.scene.addEventListener("vehicle_layer_added", onVehicleLayerAdded);

  const truthVizTree = tree.jstree('create_node', "#", { "text": "<b>Truth Visualizations</b>", "id": "truthViz"}, "last", false, false);
  tree.jstree("check_node", truthVizTree);
  let onTruthLayerAdded = (e) => {
		const truthLayer = e.truthLayer;
    let iconName = {
      "Invalid Lanes": "invalid_lanes",
      "Lanes": "lanes",
      "Tracked Objects": "tracked_objects",
      "Anomalous Tracked Objects": "anomalous_tracked_objects",
      "Vehicle Mesh": "vehicle",
      "OpenDRIVE Lanes": "lanes"
    }[truthLayer.name]
    //default is lanes icon to handle possible other lane name choices by the user
    if (!iconName) {
      iconName = "lanes"
    }
    let truthIcon = `${Potree.resourcePath}/veritasIcons/${iconName}.svg`
    if (!iconName) {
      truthIcon = `${Potree.resourcePath}/icons/cloud.svg`
    }
		let node = createNode(truthVizTree, truthLayer.name, truthIcon, truthLayer);

		addCheckListeners(truthLayer, tree, node)
	};
  window.viewer.scene.addEventListener("truth_layer_added", onTruthLayerAdded);

  const onTruthLayerDeleted = (e) => {
    const truthNodes = tree.jstree("get_children_dom", truthVizTree);
    let nodeID;
    for (let i = 0; i < truthNodes.length; i++) {
      if (truthNodes[i].textContent === e.truthLayer.name) {
        nodeID = truthNodes[i].id;
        break;
      }
    }

    if (nodeID) {
      tree.jstree('delete_node', nodeID);
    }
  };
  window.viewer.scene.addEventListener("truth_layer_deleted", onTruthLayerDeleted)

  const sensorTree = tree.jstree('create_node', "#", { "text": "<b>Sensor Readings</b>", "id": "sensorViz"}, "last", false, false);
  tree.jstree("check_node", sensorTree);

  let laneSenseTree = null
  let laneSenseLeftTree = null
  let laneSenseRightTree = null
  let lanePolynomialTree = null
  let roadModelTree = null
  let otherTree = null

  const onControlPointLayerAdded = (e) => {
    const sensorLayer = e.sensorLayer;
    let sensorIcon = `${Potree.resourcePath}/icons/cloud.svg`; // TODO Fix this
    let name = sensorLayer.name
    let parent = sensorTree
    if (name.toLowerCase().includes("lane polynomial")) {
      if (lanePolynomialTree === null) {
        lanePolynomialTree = createNodeFolderSorted(sensorTree, "<b>Lane Polynomial</b>", "lanePolynomial")
        tree.jstree("check_node", lanePolynomialTree);
      }
      parent = lanePolynomialTree
    }
    else if (name.toLowerCase().includes("lane sense")) {
      if (laneSenseTree === null) {
        laneSenseTree = createNodeFolderSorted(sensorTree, "<b>Lane Sense</b>", "laneSense")
        tree.jstree("check_node", laneSenseTree);
      }
      if (name.toLowerCase().includes("left")) {
        if (laneSenseLeftTree === null) {
          laneSenseLeftTree = createNodeFolderSorted(laneSenseTree, "<b>Left</b>", "laneSenseLeft")
          tree.jstree("check_node", laneSenseLeftTree);
        }
        parent = laneSenseLeftTree
      }
      else if (name.toLowerCase().includes("right")) {
        if (laneSenseRightTree === null) {
          laneSenseRightTree = createNodeFolderSorted(laneSenseTree, "<b>Right</b>", "laneSenseRight")
          tree.jstree("check_node", laneSenseRightTree);
        }
        parent = laneSenseRightTree
      }
    }
    else if (name.toLowerCase().includes("road model")) {
      if (roadModelTree === null) {
        roadModelTree = createNodeFolderSorted(sensorTree, "<b>Road Model</b>", "roadModel")
        tree.jstree("check_node", roadModelTree);
      }
      parent = roadModelTree
    }
    else {
      if (otherTree === null) {
        otherTree = createNodeFolderSorted(sensorTree, "<b>Other</b>", "other")
        tree.jstree("check_node", otherTree);
      }
      parent = otherTree
    }
    if (otherTree === null) {
      const nameArr = name.split(" ")
      const value = nameArr.filter(word => word.includes("."))
      name = value[0] || name
    }
    const node = createNode(parent, name, sensorIcon, sensorLayer, true);
    addCheckListeners(sensorLayer, tree, node)
  };
  window.viewer.scene.addEventListener("control_point_layer_added", onControlPointLayerAdded);

  let radarTree = null
  let mrrTree = null
  let srrTree = null
  let objectFusionTree = null
  let radarOtherTree = null

  const onRadarLayerAdded = (e) => {
    const sensorLayer = e.sensorLayer;
    let sensorIcon = `${Potree.resourcePath}/icons/cloud.svg`; // TODO Fix this
    let name = sensorLayer.name
    let nameSplitter
    if (radarTree === null) {
      radarTree = createNodeFolderSorted(sensorTree, "<b>Radar</b>", "radar")
      tree.jstree("check_node", radarTree);
    }
    parent = radarTree
    if (name.includes("MRR")) {
      if (mrrTree === null) {
        mrrTree = createNodeFolderSorted(radarTree, "<b>MRR</b>", "mrr")
        tree.jstree("check_node", mrrTree);
      }
      parent = mrrTree
      nameSplitter = "MRR "
    }
    else if (name.includes("SRR")) {
      if (srrTree === null) {
        srrTree = createNodeFolderSorted(radarTree, "<b>SRR</b>", "srr")
        tree.jstree("check_node", srrTree);
      }
      parent = srrTree
      nameSplitter = "SRR "
    }
    else if (name.includes("Fusion")) {
      if (objectFusionTree === null) {
        objectFusionTree = createNodeFolderSorted(radarTree, "<b>Object Fusion</b>", "objectFusion")
        tree.jstree("check_node", objectFusionTree);
      }
      parent = objectFusionTree
      nameSplitter = "Object Fusion "
    }
    else if (name.includes(" Tracks")) {
      if (radarOtherTree === null) {
        radarOtherTree = createNodeFolderSorted(radarTree, "<b>Other</b>", "radarOther")
        tree.jstree("check_node", radarOtherTree);
      }
      parent = radarOtherTree
      nameSplitter = ""
    }
    if (nameSplitter) {
      let nameArr = name.split(nameSplitter)
      name = ""
      for (const word of nameArr) {
        name += word
      }
    }
    const node = createNode(parent, name, sensorIcon, sensorLayer, true);
    addCheckListeners(sensorLayer, tree, node)
  };
  window.viewer.scene.addEventListener("radar_layer_added", onRadarLayerAdded);



  const assessmentsTree = tree.jstree('create_node', "#", { "text": "<b>Assessment Visualizations</b>", "id": "assessmentsViz"}, "last", false, false);
  tree.jstree("check_node", assessmentsTree);
  let onAssessmentsLayerAdded = (e) => {
    let assessmentsLayer = e.assessmentsLayer;
    let assessmentsIcon = `${Potree.resourcePath}/icons/cloud.svg`; // TODO Fix this
    let node = createNode(assessmentsTree, assessmentsLayer.name, assessmentsIcon, assessmentsLayer);

    addCheckListeners(assessmentsLayer, tree, node)
  };
  window.viewer.scene.addEventListener("assessments_layer_added", onAssessmentsLayerAdded);


  if (vizConfiguration == "customerLanes") {
    const HdMapProvidersTree = tree.jstree('create_node', "#", { "text": "<b>HD Map Providers</b>", "id": "HdMapProvidersViz"}, "last", false, false);
    tree.jstree("check_node", HdMapProvidersTree);
    let onMapProviderLayerAdded = (e) => {
      let mapProviderLayer = e.mapLayer;
      let mapIcon = `${Potree.resourcePath}/icons/focus.svg`; // TODO Find a more appropriate svg icon
      let node = createNode(HdMapProvidersTree, mapProviderLayer.name, '', mapProviderLayer);

      addCheckListeners(mapProviderLayer, tree, node)
    };
    window.viewer.scene.addEventListener("map_provider_layer_added", onMapProviderLayerAdded);
  }
}

function addCheckListeners(layer, tree, node) {
  layer.addEventListener("visibility_changed", () => {
    tree.jstree(layer.visible ? 'check_node' : 'uncheck_node', node)
  });
}

export function updateVolumePanel(viewer) {
  // NOTE Call this function only after the volume panel has been initialized
  const volumeTable = document.getElementById("volume_table")
  let roadClassification = $(`<table class="measurement_value_table">
    <tr>
      <th>label</th>
      <th>metadata</th>
      <th></th>
    </tr>
    <tr>
      <td align="center" id="cell-label" style="width: 50%">
        <select class="select-dropdown" id="labelDropdown" name="label_data">
          <option></option>
          <option value="road">Road</a>
          <option value="non_road">Non-Road</a>
          <option value="road_edge">Road Edge</a>
          <option value="lane_marking">Lane Marking</a>
          <option value="vehicle">Vehicle</a>
          <option value="obstacle">Obstacle</a>
        </select>
      </td>
      <td align="center" id="cell-metadata" style="width: 50%">
        <input type="text" id="metadata-input" style="width: 75%" placeholder="Enter to submit"></input>
      </td>
      <td align="right" style="width: 25%">
        <img name="copyScale" title="copy" class="button-icon" src="${Potree.resourcePath + '/icons/copy.svg'}" style="width: 16px; height: 16px"/>
      </td>
    </tr>
    </table>
  `)
  roadClassification.insertAfter(volumeTable)
  const labelDropdown = roadClassification.find(`#labelDropdown`)[0]
	const elMetadata = roadClassification.find(`#metadata-input`)[0]
	setSelectBoxByValue(labelDropdown, getVolData().label)
	updateMetadataText(elMetadata)
  addVolLabelListeners(viewer)
}

export function addPointAttributes(point) {
  if (point.latitude === undefined && point.longitude === undefined && window.VeritasInfo.vtmData !== undefined) {
    //adds lat/lon if they do not already exist, and are able to be calculated
    if (window.VeritasInfo.vtmData[`${point.position.x},${point.position.y}`] === undefined) {
      let sf = window.VeritasInfo.vtmData.VTMScaleFactor ? window.VeritasInfo.vtmData.VTMScaleFactor : window.VeritasInfo.vtmData.scaleFactor
      let ogLat = window.VeritasInfo.vtmData.VTMOriginLatitude ? window.VeritasInfo.vtmData.VTMOriginLatitude : window.VeritasInfo.vtmData.originLatitude
      let ogLong = window.VeritasInfo.vtmData.VTMOriginLongitude ? window.VeritasInfo.vtmData.VTMOriginLongitude : window.VeritasInfo.vtmData.originLongitude

      let coords = VTMToLatLon(point.position.x, point.position.y, ogLat, ogLong, sf);
      window.VeritasInfo.vtmData[`${point.position.x},${point.position.y}`] = {}
      window.VeritasInfo.vtmData[`${point.position.x},${point.position.y}`].latitude = coords.latitude
      window.VeritasInfo.vtmData[`${point.position.x},${point.position.y}`].longitude = coords.longitude
    }
    point.latitude = window.VeritasInfo.vtmData[`${point.position.x},${point.position.y}`].latitude
    point.longitude = window.VeritasInfo.vtmData[`${point.position.x},${point.position.y}`].longitude
  }
  return point
}
